import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { urlDB } from "./consts";
import useFormValidation from "./FormValidation";
import Preloader from "../components/Preloader/Preloader";

// хук для сброса пароля на почту
function ResetPasswordRequest() {
  const { values, isValid, errors, formRef, handleChange } =
    useFormValidation();

  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const currentLanguage = i18n.language.toLocaleLowerCase();

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isPreloader, setPreloader] = useState(false);

  function onResetPasswordRequest(email) {
    setErrorMessage(""); // Очистить предыдущие ошибки
    setSuccessMessage(""); // Очистить предыдущее сообщение об успехе
    setPreloader(true);
    fetch(`${urlDB}/auth/users/reset_password/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": currentLanguage,
      },
      body: JSON.stringify({ email }),
    })
      .then((response) => {
        setPreloader(false);
        if (response.ok) {
          return response.text().then((text) => (text ? JSON.parse(text) : {})); //если ответ JSON с бэка простой текст
        } else {
          return response.json().then((json) => {
            throw new Error(
              json.error ||
                `${t(
                  "errors.incorrect_email_or_password",
                  "Пользователя с данным адресом электронной почты не существует."
                )}`
            );
          });
        }
      })
      .then((data) => {
        //console.log('Инструкции были отправлены на почту!', data);
        setSuccessMessage(
          `${t("registr.check_email", "Проверьте Вашу электронную почту")}`
        ); // Установить сообщение об успехе
      })
      .catch((error) => {
        setPreloader(false);
        //console.error("Пользователя с данным адресом электронной почты не существует.", error);
        setErrorMessage(error.message); // Установить сообщение об ошибке
      });
  }

  const handleClick = (e) => {
    handleChange(e);
    setErrorMessage("");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrorMessage("");
    onResetPasswordRequest(values.email);
  };

  return (
    <div className="login">
      {isPreloader && (
          <div className="preloader-abs">
            <Preloader isPreloader={isPreloader} />
          </div>
        )}
      <form
        ref={formRef}
        className=  {isPreloader ? "login__form  form--grey " : "login__form"}
        onSubmit={handleSubmit}
        noValidate
      >
        <label className="login__label" htmlFor="email">
          Email:
          <input
            id="email"
            type="email"
            value={values.email || ""}
            onChange={handleClick}
            className="login__input"
            name="email"
            minLength="6"
            maxLength="40"
            pattern="^(http(s){0,1}:\/\/.){0,1}[\-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([\-a-zA-Z0-9@:%_\+.~#?&\/\/=]*)$"
            required
          />
          <span
            className={`${
              errors.email ? "login__error" : "login__error_hidden"
            }`}
          >
            {t(
              "errors.enter_valid_email",
              "Введите валидную почту(name@mail.com)"
            )}
          </span>
        </label>
        <p className="register__error-text">{errorMessage || successMessage}</p>
        <button
          disabled={!isValid}
          className={`login__submit-button ${
            !isValid ? "login__submit-button_disable" : "app__button-opacity"
          }`}
          aria-label="Сбросить пароль"
          type="submit"
        >
          {t("registr.password_req", "Сбросить пароль")}
        </button>
      </form>

      <button
        className="not-found__button app__text-opacity"
        onClick={() => navigate(-1)}
      >
        {t("not-found.back", "Назад")}
      </button>
    </div>
  );
}

export default ResetPasswordRequest;
