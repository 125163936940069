import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "./AuthContext";
import MainApi from "../utils/MainApi";

export const CurrentUserContext = React.createContext();

export const CurrentUserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState({ addresses: [] });
  const [addresses, setAddresses] = useState([]);
  const [orders, setOrders] = useState([]);
  const { logIn, logout } = useContext(AuthContext);

  function getAddressApi() {
    // setPreloader(true);
    MainApi.getUserAdress()
      .then((addresses) => {
        setCurrentUser((user) => ({ ...user, addresses }));
        setAddresses(addresses);
        localStorage.setItem("addresses", JSON.stringify(addresses));
        // setPreloader(false);
      })
      .catch((err) => {
        // handleAuthError(err);
        console.log(err);
        // setPreloader(false);
      });
  }

  function getOrdersApi() {
    // setPreloader(true);
    if (!logIn) {
      return;
    }
    MainApi.getUserOrders()
      .then((orders) => {
        setCurrentUser((user) => ({ ...user, orders }));
        setOrders(orders);
        localStorage.setItem("orders", JSON.stringify(orders));
        // setPreloader(false);
      })
      .catch((err) => {
        // handleAuthError(err);
        console.log(err);
        // setPreloader(false);
      });
  }

  function handleDeleteAddress(id) {
    // setPreloader(true);
    if (!logIn) {
      return;
    }
    MainApi.deleteUserAdress(id)
      .then(() => {
        let updatedAddresses;

        setAddresses((prevAddresses) => {
          updatedAddresses = prevAddresses.filter(
            (addressItem) => addressItem.id !== id
          );
          localStorage.setItem("addresses", JSON.stringify(updatedAddresses));
          return updatedAddresses;
        });
        setCurrentUser((prev) => ({ ...prev, addresses: updatedAddresses }));
        // setPreloader(false);
      })
      .catch((err) => {
        console.log(err);
        // setPreloader(false);
      });
  }

  function handlePostUserAddress(newAddress, callback) {
    // setPreloader(true);
    if (!logIn) {
      return;
    }
    const addressExists = addresses.some(
      (addressItem) => addressItem.id === newAddress.id
    );
    if (addressExists) {
      handleDeleteAddress(newAddress.id);
    } else {
      MainApi.postUserAdress(newAddress)
        .then((savedAddress) => {
          let updatedAddresses;
          setAddresses((prevAddresses) => {
            updatedAddresses = [...prevAddresses, savedAddress];
            localStorage.setItem("addresses", JSON.stringify(updatedAddresses));
            return updatedAddresses;
          });
          setCurrentUser((prev) => ({ ...prev, addresses: updatedAddresses }));

          // setPreloader(false);
          if (callback) callback(); // если есть колбэк, вызываем его
        })
        .catch((err) => {
          console.log(err);
          // setPreloader(false);
        });
    }
  }

  const handleUpdateProfile = function (
    city,
    first_name,
    last_name,
    phone,
    date_of_birth,
    messenger_account,
    language
  ) {
    if (!logIn) {
      return;
    }
    // setPreloader(true);

    return new Promise((resolve, reject) => {
      MainApi.changeUserInformation({
        city,
        first_name,
        last_name,
        phone,
        date_of_birth,
        messenger_account,
        language
      })
        .then((res) => {
          setCurrentUser((current) => {
            const updatedUser = { ...current, ...res };
            if (!res.addresses && current.addresses) {
              updatedUser.addresses = current.addresses;
            }
            return updatedUser;
          });
          resolve(res);
          // setErrorMessage("errors.success_change_profile");
          // setPreloader(false);
        })
        .catch((err) => {
          if (err.message) {
            reject(err.message);
          } else {
            reject(err);
          }
          // setPreloader(false);
        });


    })

  };



  useEffect(() => {
    if (logIn) {
      Promise.all([MainApi.getUserId()])
        .then(([userData, addressesData, ordersData]) => {
          setCurrentUser(userData);
          getAddressApi(addressesData);
          localStorage.setItem("addresses", JSON.stringify(addressesData));
          getOrdersApi(ordersData);
          localStorage.setItem("orders", JSON.stringify(ordersData));
          // getCoupons(couponsData);
          //localStorage.setItem('coupons', JSON.stringify(couponsData);
        })
        .catch((err) => {
          console.log(err);
          if (err.message?.code && err.message.code === "user_not_found") {
            logout();
          }
        });
    } else {
      setCurrentUser({});
      localStorage.removeItem("addresses");
      localStorage.removeItem("orders");
      setAddresses({ addresses: [] });
      setOrders([]);
    }
  }, [logIn]);

  const contextData = {
    currentUser,
    addresses,
    orders,
    setCurrentUser,
    getOrdersApi,
    getAddressApi,
    handleUpdateProfile,
    handleDeleteAddress,
    handlePostUserAddress,
  };

  return (
    <CurrentUserContext.Provider value={contextData}>
      {children}
    </CurrentUserContext.Provider>
  );
};
