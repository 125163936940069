import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AuthContext } from "./../contexts/AuthContext";
import useFormValidation from "./FormValidation";

const errorsSet = {
  new_password: {
    "This password is too common.": "password_common",
    "This password is entirely numeric.": "password_digital",
    "The password is too similar to the last_name.": "password_similar",
    "The password is too similar to the email.": "password_similar",
    "This password is too short. It must contain at least 8 characters.":
      "password_short",
    "Ensure this field has no more than 254 characters.": "long_field",
    "Password must contain at least one letter and one digit.":
      "enter_password_of_min_8_chars",
  },
};

const checkErrorCode = (key, message) => {
  if (errorsSet[key] && errorsSet[key][message]) {
    return "errors." + errorsSet[key][message];
  } else {
    return "errors.error_during_data_change";
  }
};

//подтверждение сброса пароля и введение нового
function ResetPasswordConfirm({ language }) {
  const { t } = useTranslation();
  const { values, isValid, errors, formRef, handleChange } =
    useFormValidation();
  const { handleResetPassword } = useContext(AuthContext);

  const [uid, setUid] = useState("");
  const [token, setToken] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const path = window.location.pathname; // Получаем текущий путь
    const paths = path.split("/"); // Разбиваем путь по слэшам
    // Например, для /reset_password_confirm/NQ/c34o... paths будет ["", "reset_password_confirm", "NQ", "c34o..."]
    if (paths.length >= 4) {
      // Убеждаемся, что параметры присутствуют
      setUid(paths[2]);
      setToken(paths[3]);
    }
  }, []);

  function onResetPasswordConfirm(uid, token, newPassword, language) {
    handleResetPassword(uid, token, newPassword, language)
      .then((res) => {
        navigate("/login");
      })
      .catch((err) => {
        console.log("Ошибка подтверждения сброса пароля:", err);
        if (typeof err === "string") {
          setErrorMessage("errors.error_during_registration"); // все прочие ошибки в процессе регистрации
        } else {
          for (let key in err) {
            setErrorMessage((prev) => {
              return { ...prev, [key]: err[key] };
            });
          }
        }
      });
  }

  const handleInput = (e) => {
    handleChange(e);
    setErrorMessage(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrorMessage(false);
    onResetPasswordConfirm(uid, token, values.password);
  };

  return (
    <div className="login">
      <form
        ref={formRef}
        className="login__form"
        onSubmit={handleSubmit}
        noValidate
      >
        <label className="login__label">
          {t("profile.new_password", "Новый пароль")}
          <input
            id="password"
            className="login__input"
            type="password"
            name="password"
            value={values.password || ""}
            onChange={handleInput}
            minLength="8"
            maxLength="100"
            pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,100}$"
            required
          />
          <span
            className={`${
              errors.password || errorMessage.new_password
                ? "login__error"
                : "login__error_hidden"
            }`}
          >
            {errors.password ? (
              <>
                {t(
                  "errors.enter_password_of_min_8_chars",
                  "Пароль должен состоять из цифр и латинских букв, длина не менее 8 символов"
                )}
              </>
            ) : errorMessage?.new_password ? (
              <>
                {t(checkErrorCode("new_password", errorMessage.new_password))}
              </>
            ) : null}
          </span>
        </label>
        <button
          disabled={!isValid}
          className={`login__submit-button ${
            !isValid ? "login__submit-button_disable" : "app__button-opacity"
          }`}
          type="submit"
          aria-label="Установить новый пароль"
        >
          {t("registr.password_confirm", "Установить новый пароль")}
        </button>
      </form>
    </div>
  );
}

export default ResetPasswordConfirm;
