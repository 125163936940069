// url Database
import { urlDB } from "./consts";

// login, register, token Users



const auth = ({ city, first_name, last_name, email, phone, password, language }) => {
  return fetch(`${urlDB}/auth/users/`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Accept-Language": language, // передаём принудительно заголовок, чтобы получить ответ на английском
    },
    credentials: "include",
    body: JSON.stringify({
      city,
      first_name,
      last_name,
      phone,
      email,
      password,
    }),
  }).then(handleResult);
};


const login = ({ email, password }) => {
  return fetch(`${urlDB}/auth/jwt/create/`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({ email, password }),
  }).then(handleResult);
};

const newEmailRequest = ({ current_password, new_email }) => {
  return fetch(`${urlDB}/auth/users/set_email/`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("logInJwt")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({ current_password, new_email }),
  }).then(handleResult);
  
  
  // .then((response) => {
  //   if (response.ok) {
  //     return response.text().then((text) => (text ? JSON.parse(text) : {}));
  //   } else {
  //     // Если в ответе есть JSON с описанием ошибки, лучше его использовать
  //     return response.json().then((json) => {
  //       throw new Error(json.detail || "Ошибка изменения почты в профиле");
  //     });
  //   }
  // });
};

const newPasswordRequest = ({ current_password, new_password }) => {
  return fetch(`${urlDB}/auth/users/set_password/`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("logInJwt")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({ current_password, new_password }),
  }).then(handleResult);
  
  // .then((response) => {
  //   if (response.ok) {
  //     return response.text().then((text) => (text ? JSON.parse(text) : {}));
  //   } else {
  //     // Если в ответе есть JSON с описанием ошибки, лучше его использовать
  //     return response.json().then((json) => {
  //       throw new Error(json.detail || "Ошибка изменения пароля в профиле");
  //     });
  //   }
  // });
};


const resetPassword = (uid, token, newPassword, language ) => {
  return fetch(`${urlDB}/auth/users/reset_password_confirm/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": language,
    },
    body: JSON.stringify({ uid, token, new_password: newPassword }),
  }).then(handleResult);
};

const tokenRefresh = (refreshToken) => {
  return fetch(`${urlDB}/auth/jwt/refresh/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({ refresh: refreshToken }),
  }).then(error);
};

const tokenVerify = (accessToken) => {
  return fetch(`${urlDB}/auth/jwt/verify/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({ token: accessToken }),
  }).then(handleResult);
};

// const testOtherErrors = () => {
//     return Promise.reject('Another_error');
// }

const handleResult = (res) => {
  if (res.ok) {
    return res.json();
  } else {
    return res
      .json()
      .then((error) => Promise.reject(error));
  }
};

const error = (res) => {
  if (res.ok) {
    return res.json();
  }
  return Promise.reject(`Ошибка: ${res.status}`);
};



export {
  auth,
  login,
  tokenRefresh,
  tokenVerify,
  newEmailRequest,
  newPasswordRequest,
  resetPassword,
};
