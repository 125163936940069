import React, { useEffect, useState, useContext, useRef } from "react";
import { CurrentUserContext } from "../../contexts/CurrentUserContext";
import useFormValidation from "../../utils/FormValidation";
import ProfileNav from "../ProfileNav/ProfileNav";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../contexts/AuthContext";
import Select from "@mui/material/Select";
import { FormControl } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import "./Profile.css";

const errorsSet = {
  new_password: {
    "This password is too common.": "password_common",
    "This password is entirely numeric.": "password_digital",
    "The password is too similar to the last_name.": "password_similar",
    "The password is too similar to the email.": "password_similar",
    "This password is too short. It must contain at least 8 characters.":
      "password_short",
    "Ensure this field has no more than 254 characters.": "long_field",
    "Password must contain at least one letter and one digit.":
      "enter_password_of_min_8_chars",
  },
  first_name: {
    "Please check your name, note that only letters are allowed.":
      "enter_text_of_min_two_letters",
    "Ensure this field has no more than 150 characters.": "long_field",
  },
  last_name: {
    "Please check your name, note that only letters are allowed.":
      "enter_text_of_min_two_letters",
    "Ensure this field has no more than 150 characters": "long_field",
  },
  phone: {
    "The phone number entered is not valid.": "phone_invalid",
    "WEB account with such phone already exists.": "phone_exist",
    "Ensure this field has no more than 128 characters.": "long_field",
    "Enter a valid phone number.": "phone_invalid",
  },
  email: {
    "Enter a valid email address.": "email_invalid",
    "WEB account with this email already exists.": "user_already_exists",
    "Ensure this field has no more than 254 characters.": "long_field",
  },
  date_of_birth: {
    "Birthdate is more than 100 years ago.": "too_old",
    "Birthdate is in future.": "too_young",
  },
  current_password: {
    "Invalid password.": "invalid_password",
    "This field is required.": "invalid_password",
  },
  new_email: {
    "WEB account with this email already exists.": "user_already_exists",
  },
  messenger_account: {
    "Both 'msngr_username' and 'msngr_type' must be filled.":
      "messangers_field",
    "Wts username must start with '+' or invalid phone number.":
      "messangers_wts_invalid",
    "TM username must start with '@' or contains invalid symbols.":
      "messangers_tg_invalid",
    "TM username from 5 to 32 symbols.": "messangers_tg_symbols",
    "Msngr_type not 'tm'/'wts'.": "messangers_type",
    "Such account already exists.": "messangers_acc",
  },
};

const checkErrorCode = (key, message) => {
  if (errorsSet[key] && errorsSet[key][message]) {
    return "errors." + errorsSet[key][message];
  } else {
    return "errors.error_during_data_change";
  }
};

function Profile({
  setFinalOrderInfo,
  handlePromo,
  language,
  selectedCity,
  aboutUs,
}) {
  const { t } = useTranslation();
  const { logout, handleChangeEmail, handleChangePassword } =
    useContext(AuthContext);
  const { currentUser, handleUpdateProfile, setCurrentUser } =
    useContext(CurrentUserContext);
  const [isDisableInput, setDisableInput] = useState(true);
  const [isDisableEmailInput, setDisableEmailInput] = useState(true);
  const [isDisablePasswordInput, setDisablePasswordInput] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    values,
    isValid,
    errors,
    formRef,
    handleChange,
    setValues,
    formatDateToServer,
    formatDateToInput,
  } = useFormValidation(currentUser);

  useEffect(() => {
    const defaultmessenger_accountData = {
      msngr_type: "",
      msngr_username: "",
    };
    const inputData = { messenger_account: defaultmessenger_accountData };
    if (currentUser) {
      Object.keys(currentUser).forEach((key) => {
        if (key === "date_of_birth") {
          inputData[key] = formatDateToInput(currentUser[key]);
        } else if (
          key === "messenger_account" &&
          currentUser.messenger_account
        ) {
          inputData["messenger_account"] = {
            msngr_type: currentUser.messenger_account?.msngr_type || "",
            msngr_username: currentUser.messenger_account?.msngr_username || "",
          };
        } else if (key === "email") {
          // Убедитесь, что используете правильное имя поля с email
          inputData["newEmail"] = currentUser[key]; // Присваиваем значение email полю newEmail
        } else if (key !== "messenger_account") {
          inputData[key] = currentUser[key];
        }
      });
      setValues(inputData);
    }
  }, [currentUser, formatDateToInput, setValues]);

  function handleSubmit(event) {
    event.preventDefault();
    setErrorMessage(false);
    setLoading(true);
    const formattedDateOfBirth = values.date_of_birth
      ? formatDateToServer(values.date_of_birth)
      : null;

    const formattedMessenger =
      values.messenger_account.msngr_type &&
      values.messenger_account.msngr_username
        ? values.messenger_account
        : null;

    const formattedMessen = formattedMessenger
      ? {
          ...formattedMessenger,
          msngr_username:
            formatMessengerUsername(values) +
            values.messenger_account.msngr_username,
        }
      : null;
    handleUpdateProfile(
      values.city,
      values.first_name,
      values.last_name,
      values.phone,
      formattedDateOfBirth,
      formattedMessen,
      language
    )
      .then((res) => {
        console.log("ok");
        setLoading(false);
        setDisableInput(true);
      })
      .catch((err) => {
        setLoading(false);
        if (typeof err === "string") {
          setErrorMessage("errors.error_during_registration"); // все прочие ошибки в процессе регистрации
        } else {
          for (let key in err) {
            setErrorMessage((prev) => {
              return { ...prev, [key]: err[key] };
            });
          }
        }
      });
    // Деактивируем ввод в форму
  }

  function formatMessengerUsername(values) {
    if (values.messenger_account.msngr_username) {
      if (
        values.messenger_account.msngr_type === "tm" &&
        !values.messenger_account.msngr_username.startsWith("@")
      ) {
        return `@`;
      } else if (
        values.messenger_account.msngr_type === "wts" &&
        !values.messenger_account.msngr_username.startsWith("+")
      ) {
        return `+`;
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  function handleSubmitEmail(event) {
    event.preventDefault();
    setErrorMessage(false);
    setLoading(true);
    if (values.newEmail && values.newEmail !== currentUser.email) {
      handleChangeEmail(values.currentPassword, values.newEmail)
        .then((res) => {
          console.log("ok");
          setDisableEmailInput(true);
          setLoading(false);
        })
        .catch((err) => {
          console.log("ok");
          setLoading(false);
          if (typeof err === "string") {
            setErrorMessage("errors.error_during_registration"); // все прочие ошибки в процессе регистрации
          } else {
            for (let key in err) {
              setErrorMessage((prev) => {
                return { ...prev, [key]: err[key] };
              });
            }
          }
        });
    }
  }

  function handleSubmitPassword(event) {
    event.preventDefault();
    setErrorMessage(false);
    setLoading(true);
    if (values.newPassword && values.newPassword !== currentUser.password) {
      handleChangePassword(values.currentPassword, values.newPassword)
        .then((res) => {
          console.log("ok");
          setDisablePasswordInput(true);
          setLoading(false);
        })
        .catch((err) => {
          console.log("ok");
          setLoading(false);
          if (typeof err === "string") {
            setErrorMessage("errors.error_during_registration"); // все прочие ошибки в процессе регистрации
          } else {
            for (let key in err) {
              setErrorMessage((prev) => {
                return { ...prev, [key]: err[key] };
              });
            }
          }
        });
    }
  }

  function changeUserInformation() {
    setErrorMessage(false);
    setDisableInput(false);
    setDisableEmailInput(true);
    setDisablePasswordInput(true);
  }

  function changeUserEmailInformation() {
    setErrorMessage(false);
    setDisableEmailInput(false);
    setDisableInput(true);
    setDisablePasswordInput(true);
  }

  function changeUserPasswordInformation() {
    setErrorMessage(false);
    setDisablePasswordInput(false);
    setDisableInput(true);
    setDisableEmailInput(true);
  }

  function handleLogOut() {
    logout();
    setErrorMessage(false);
    setFinalOrderInfo({
      amount: false,
      total_discount: false,
      total_amount: false,
      promocode: null,
    });
    setCurrentUser({ addresses: [] });
    handlePromo("");
  }

  const handleInput = (e) => {
    handleChange(e);
    // setErrorMessage(false);
  };

  return (
    <>
      <ProfileNav />
      <div className="profile">
        <h2 className="profile__text">
          {t("profile.hello", "Привет,")} {currentUser.first_name}!
        </h2>
        <form
          ref={!isDisableInput ? formRef : null}
          className="profile__form"
          onSubmit={handleSubmit}
        >
          <div className="profile__container">
            <label className="profile__label" htmlFor="first_name">
              {t("profile.first_name", "Имя")}
              <input
                disabled={isDisableInput}
                value={values.first_name || ""}
                onChange={handleInput}
                id="first_name"
                className="profile__input"
                name="first_name"
                type="text"
                placeholder={t("profile.first_name", "Имя")}
                minLength="2"
                maxLength="300"
                pattern="^[A-Za-zА-Яа-яЂ-џЈ-џђ-џљњћџČčĆćDždžĐđLjljNjnjŠšŽž]{2,150}$"
                required
              />
            </label>
            <span
              className={`${
                errors.first_name || errorMessage.first_name
                  ? "profile__error"
                  : "profile__error_hidden"
              }`}
            >
              {/* {t(
                "errors.enter_text_of_min_two_letters",
                "Введите имя или фамилию от 2 букв"
              )} */}
              {errors.first_name ? (
                <>
                  {t(
                    "errors.enter_text_of_min_two_letters",
                    "Введите имя или фамилию от 2 букв"
                  )}
                </>
              ) : errorMessage?.first_name ? (
                <>{t(checkErrorCode("first_name", errorMessage.first_name))}</>
              ) : null}
            </span>
          </div>
          <div className="profile__container">
            <label className="profile__label" htmlFor="last_name">
              {t("profile.last_name", "Фамилия")}
              <input
                disabled={isDisableInput}
                value={values.last_name || ""}
                onChange={handleInput}
                id="last_name"
                className="profile__input"
                name="last_name"
                type="text"
                placeholder={t("profile.last_name", "Фамилия")}
                minLength="2"
                maxLength="300"
                pattern="^[A-Za-zА-Яа-яЂ-џЈ-џђ-џљњћџČčĆćDždžĐđLjljNjnjŠšŽž0-9\s]+$"
                required
              />
            </label>
            <span
              className={`${
                errors.last_name || errorMessage.last_name
                  ? "profile__error"
                  : "profile__error_hidden"
              }`}
            >
              {errors.last_name ? (
                <>
                  {t(
                    "errors.enter_text_of_min_two_letters",
                    "Введите имя или фамилию от 2 букв"
                  )}
                </>
              ) : errorMessage?.last_name ? (
                <>{t(checkErrorCode("last_name", errorMessage.last_name))}</>
              ) : null}
            </span>
          </div>
          <div className="profile__container city">
            <label
              className="profile__label profile__label-messenger_account"
              htmlFor="city"
            >
               {t("profile.city", "Город")}
              <select
                disabled={isDisableInput}
                value={values.city}
                onChange={handleInput}
                id="city"
                className="profile__input"
                name="city"
                // required
              >
                {aboutUs?.map((city) => {
                  return (
                    <option key={city.city} value={city.city}>{city.city}</option>
                  );
                })}
              </select>
            </label>
          </div>
          <div className="profile__container">
            <label className="profile__label" htmlFor="phone">
              {t("profile.phone", "Телефон")}
              <input
                disabled={isDisableInput}
                value={values.phone || ""}
                onChange={handleInput}
                id="phone"
                className="profile__input"
                name="phone"
                type="tel"
                placeholder="+"
                minLength="11"
                maxLength="128"
                pattern="^\+[0-9]{11,128}$"
                required
              />
            </label>
            <span
              className={`${
                errors.phone || errorMessage.phone
                  ? "profile__error"
                  : "profile__error_hidden"
              }`}
            >
              {errors.phone ? (
                <>
                  {t(
                    "errors.enter_valid_phone_starting_with_plus",
                    'Номер телефона должен начинаться с "+" и содержать от 11 до 14 цифр'
                  )}
                </>
              ) : errorMessage?.phone ? (
                <>{t(checkErrorCode("phone", errorMessage.phone))}</>
              ) : null}
            </span>
          </div>
          <div className="profile__container">
            <label className="profile__label" htmlFor="date_of_birth">
              {t("profile.date_of_birth", "Дата рождения")}
              <input
                disabled={isDisableInput}
                value={values.date_of_birth || ""}
                onChange={handleInput}
                id="date_of_birth"
                className="profile__input"
                name="date_of_birth"
                type="date"
                //required
              />
            </label>
            <span
              className={
                errors.date_of_birth || errorMessage.date_of_birth
                  ? "profile__error"
                  : "profile__error_hidden"
              }
            >
              {errors.date_of_birth ? (
                <>
                  {t(
                    "profile.enter_birth_date",
                    "Введите дату своего рождения"
                  )}
                </>
              ) : errorMessage?.date_of_birth ? (
                <>
                  {t(
                    checkErrorCode("date_of_birth", errorMessage.date_of_birth)
                  )}
                </>
              ) : null}
            </span>
          </div>
          <div className="profile__container">
            <label
              className="profile__label profile__label-messenger_account"
              htmlFor="messenger_account-type"
            >
              {t("profile.type_messenger_account", "Мессенджер")}
              <select
                disabled={isDisableInput}
                value={values.messenger_account.msngr_type}
                onChange={handleInput}
                id="messenger_account-type"
                className="profile__input"
                name="msngr_type"
                // required
              >
                <option value="">
                  {t("profile.choose_messenger_account", "Не выбрано")}
                </option>
                <option value="tm">Telegram</option>
                <option value="wts">WhatsApp</option>
              </select>
            </label>
            {values.messenger_account.msngr_type && (
              <label className="profile__label" htmlFor="msngr_username">
                Messenger
                <input
                  disabled={isDisableInput}
                  value={
                    formatMessengerUsername(values) +
                    values.messenger_account.msngr_username
                  }
                  onChange={handleInput}
                  id="messenger_account"
                  className="profile__input"
                  // name={
                  //   values.messenger_account.msngr_type === "tm"
                  //     ? "msngr_username"
                  //     : "msngr_username"
                  // }
                  name="msngr_username"
                  placeholder={
                    values.messenger_account.msngr_type === "tm"
                      ? "@username"
                      : "+"
                  }
                  minLength={
                    values.messenger_account.msngr_type === "wts" ? 10 : 3
                  }
                  maxLength="50"
                  pattern={
                    values.messenger_account.msngr_type === "tm"
                      ? "^@[A-Za-z0-9_]{3,32}$"
                      : "^\\+[0-9]{11,50}$"
                  }
                  required={values.messenger_account.msngr_type ? true : false}
                  type={
                    values.messenger_account.msngr_type === "tm"
                      ? "text"
                      : "tel"
                  }
                />
              </label>
            )}

            <span
              className={`${
                errors.messenger_account || errorMessage.messenger_account
                  ? "profile__error"
                  : "profile__error_hidden"
              }`}
            >
              {errors.messenger_account ? (
                <>
                  {t(
                    "profile.enter_telegram_or_whatsapp",
                    "Введите ник Telegram или номер Whatsapp"
                  )}
                </>
              ) : errorMessage?.messenger_account ? (
                <>
                  {t(
                    checkErrorCode(
                      "messenger_account",
                      errorMessage.messenger_account
                    )
                  )}
                </>
              ) : null}
            </span>
          </div>

          {
            !isDisableInput && (
              <button
                disabled={!isValid}
                onClick={handleSubmit}
                className={`app__text-opacity ${loading && "button--loading"}
                                ${
                                  isValid &&
                                  (currentUser.first_name !==
                                    values.first_name ||
                                    currentUser.last_name !==
                                      values.last_name ||
                                    currentUser.phone !== values.phone ||
                                    currentUser.date_of_birth !==
                                      values.date_of_birth ||
                                    currentUser.messenger_account
                                      .msngr_username !==
                                      values.messenger_account.msngr_username ||
                                    currentUser.messenger_account.msngr_type !==
                                      values.messenger_account.msngr_type)
                                    ? "profile__button-save"
                                    : "profile__button-save_disable"
                                }`}
                type="submit"
                aria-label={t("profile.save", "Сохранить")}
              >
                {loading ? "" : t("profile.save", "Сохранить")}
              </button>
            )

            // : (
            //   // <button
            //   //   onClick={changeUserInformation}
            //   //   className="app__text-opacity profile__submit-button"
            //   //   type="button"
            //   //   aria-label={t("profile.edit", "Редактировать профиль")}
            //   // >
            //   //   {t("profile.edit", "Редактировать профиль")}
            //   // </button>
            // )
          }
        </form>
        {isDisableInput && !loading && (
          <button
            onClick={changeUserInformation}
            className="app__text-opacity profile__submit-button"
            type="button"
            aria-label={t("profile.edit", "Редактировать профиль")}
          >
            {t("profile.edit", "Редактировать профиль")}
          </button>
        )}

        <form
          ref={!isDisableEmailInput ? formRef : null}
          className="profile__form"
          onSubmit={handleSubmitEmail}
          noValidate
        >
          <div className="profile__container">
            <label className="profile__label" htmlFor="currentPassword">
              {t("profile.current_password", "Текущий пароль")}

              {!isDisableEmailInput && (
                <input
                  value={values.currentPassword || ""}
                  onChange={handleInput}
                  className="profile__input"
                  id="currentPassword"
                  name="currentPassword"
                  type="password"
                  minLength="8"
                  maxLength="128"
                  pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,128}$"
                  placeholder={t(
                    "profile.current_password",
                    "Введите теущий пароль"
                  )}
                  required
                />
              )}
            </label>

            {!isDisableEmailInput && (
              <span
                className={
                  errors.currentPassword || errorMessage.current_password
                    ? "profile__error"
                    : "profile__error_hidden"
                }
              >
                {errors.currentPassword ? (
                  <>
                    {t(
                      "errors.enter_password_of_min_8_chars",
                      "Пароль должен состоять из цифр и латинских букв, длина не менее 8 символов"
                    )}
                  </>
                ) : errorMessage?.current_password ? (
                  <>
                    {t(
                      checkErrorCode(
                        "current_password",
                        errorMessage.current_password
                      )
                    )}
                  </>
                ) : null}
              </span>
            )}
          </div>
          <div className="profile__container">
            <label className="profile__label" htmlFor="newEmail">
              E-mail
              <input
                value={values.newEmail || ""}
                onChange={handleInput}
                id="newEmail"
                className="profile__input"
                name="newEmail"
                type="email"
                placeholder="Email"
                minLength="6"
                maxLength="254"
                pattern="^(http(s){0,1}:\/\/.){0,1}[\-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([\-a-zA-Z0-9@:%_\+.~#?&\/\/=]*)$"
                required
              />
            </label>
            <span
              className={
                errors.newEmail || errorMessage.new_email
                  ? "profile__error"
                  : "profile__error_hidden"
              }
            >
              {errors.newEmail ? (
                <>
                  {t(
                    "errors.enter_valid_email",
                    "Введите валидную почту(name@mail.com)"
                  )}
                </>
              ) : errorMessage?.new_email ? (
                <>{t(checkErrorCode("new_email", errorMessage.new_email))}</>
              ) : null}
            </span>
          </div>
        </form>

        {!isDisableEmailInput ? (
          <button
            disabled={!isValid}
            onClick={handleSubmitEmail}
            className={`app__text-opacity 
                                ${
                                  !isValid
                                    ? "profile__button-save_disable"
                                    : "profile__button-save"
                                }`}
            type="submit"
            aria-label={t("profile.save", "Сохранить")}
          >
            {t("profile.save", "Сохранить")}
          </button>
        ) : (
          <button
            onClick={changeUserEmailInformation}
            className="app__text-opacity profile__submit-button"
            type="button"
            aria-label={t("profile.post", "Изменить почту")}
          >
            {t("profile.post", "Изменить почту")}
          </button>
        )}

        <form
          ref={!isDisablePasswordInput ? formRef : null}
          className="profile__form"
          onSubmit={handleSubmitPassword}
          noValidate
        >
          <div className="profile__container">
            <label className="profile__label" htmlFor="currentPassword">
              {t("profile.current_password", "Текущий пароль")}
              {!isDisablePasswordInput && (
                <input
                  value={values.currentPassword || ""}
                  onChange={handleInput}
                  id="currentPassword"
                  className="profile__input"
                  name="currentPassword"
                  type="password"
                  minLength="8"
                  maxLength="128"
                  pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,128}$"
                  placeholder={t(
                    "profile.current_password",
                    "Введите теущий пароль"
                  )}
                  required
                />
              )}
            </label>
            {!isDisablePasswordInput && (
              <span
                className={
                  errors.currentPassword || errorMessage.current_password
                    ? "profile__error"
                    : "profile__error_hidden"
                }
              >
                {errors.currentPassword ? (
                  <>
                    {t(
                      "errors.enter_password_of_min_8_chars",
                      "Пароль должен состоять из цифр и латинских букв, длина не менее 8 символов"
                    )}
                  </>
                ) : errorMessage?.current_password ? (
                  <>
                    {t(
                      checkErrorCode(
                        "current_password",
                        errorMessage.current_password
                      )
                    )}
                  </>
                ) : null}
              </span>
            )}
          </div>

          <div className="profile__container">
            <label className="profile__label" htmlFor="newPassword">
              {t("profile.new_password", "Новый пароль")}

              {!isDisablePasswordInput && (
                <input
                  value={values.newPassword || ""}
                  onChange={handleChange}
                  className="profile__input"
                  name="newPassword"
                  id="newPassword"
                  type="password"
                  minLength="8"
                  maxLength="128"
                  pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,128}$"
                  placeholder={t(
                    "profile.new_password",
                    "Введите новый пароль"
                  )}
                  required
                />
              )}
            </label>

            <span
              className={
                errors.newPassword || errorMessage.new_password
                  ? "profile__error"
                  : "profile__error_hidden"
              }
            >
              {errors.currentPassword ? (
                <>
                  {t(
                    "errors.enter_password_of_min_8_chars",
                    "Пароль должен состоять из цифр и латинских букв, длина не менее 8 символов"
                  )}
                </>
              ) : errorMessage?.new_password ? (
                <>
                  {t(checkErrorCode("new_password", errorMessage.new_password))}
                </>
              ) : null}
            </span>
          </div>
        </form>

        {!isDisablePasswordInput ? (
          <button
            disabled={!isValid}
            onClick={handleSubmitPassword}
            className={`app__text-opacity 
                                ${
                                  !isValid
                                    ? "profile__button-save_disable"
                                    : "profile__button-save"
                                }`}
            type="submit"
            aria-label={t("profile.save", "Сохранить")}
          >
            {t("profile.save", "Сохранить")}
          </button>
        ) : (
          <>
            <button
              onClick={changeUserPasswordInformation}
              className="app__text-opacity profile__submit-button"
              type="button"
              aria-label={t("profile.change_password", "Изменить пароль")}
            >
              {t("profile.change_password", "Изменить пароль")}
            </button>
          </>
        )}
        <button
          type="button"
          className="profile__signout"
          onClick={handleLogOut}
        >
          {t("profile.log_out", "Выйти из аккаунта")}
        </button>
        <p className="register__error-text">
          {/* {errorMessage && t(errorMessage)} */}
        </p>
      </div>
    </>
  );
}

export default Profile;
