import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useFormValidation from "../../utils/FormValidation";
import logo from "../../images/logo.png";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import Preloader from "../Preloader/Preloader";
import PopupRagistration from "../PopupDish/PopupRegistration";
import "./Register.css";

const errorsSet = {
  password: {
    "This password is too common.": "password_common",
    "This password is entirely numeric.": "password_digital",
    "The password is too similar to the name.": "password_similar",
    "The password is too similar to the last_name.": "password_similar",
    "The password is too similar to the email.": "password_similar",
    "This password is too short. It must contain at least 8 characters.":
      "password_short",
    "Ensure this field has no more than 254 characters.": "long_field",
    "Password must contain at least one letter and one digit.":
      "enter_password_of_min_8_chars",
  },
  first_name: {
    "Please check your name, note that only letters are allowed.":
      "enter_text_of_min_two_letters",
    "Ensure this field has no more than 150 characters.": "long_field",
  },
  last_name: {
    "Please check your name, note that only letters are allowed.":
      "enter_text_of_min_two_letters",
    "Ensure this field has no more than 150 characters": "long_field",
  },
  phone: {
    "The phone number entered is not valid.": "phone_invalid",
    "WEB account with this phone already exists.": "phone_exist",
    "Ensure this field has no more than 128 characters.": "long_field",
  },
  email: {
    "Enter a valid email address.": "email_invalid",
    "WEB account with this email already exists.": "user_already_exists",
    "Ensure this field has no more than 254 characters.": "long_field",
  },
};

const checkErrorCode = (key, message) => {
  if (errorsSet[key] && errorsSet[key][message]) {
    return "errors." + errorsSet[key][message];
  } else {
    return "errors.error_during_registration";
  }
};

function Register({ aboutUs, language }) {
  const { t } = useTranslation();
  const { handleRegister, isPreloader } = useContext(AuthContext);
  const { values, isValid, errors, formRef, handleChange } =
    useFormValidation();
  const [errorMessage, setErrorMessage] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false); // используем для попапа при успешной регистрации
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage(false);
    handleRegister(
      values.city || "Beograd",
      values.first_name,
      values.last_name,
      values.email,
      values.phone,
      values.password,
      language
    )
      .then((res) => {
        setIsSuccess(true);
      })
      .catch((err) => {
        if (typeof err === "string") {
          setErrorMessage("errors.error_during_registration"); // все прочие ошибки в процессе регистрации
        } else {
          for (let key in err) {
            setErrorMessage((prev) => {
              return { ...prev, [key]: err[key] };
            });
          }
        }
      });
  };

  useEffect(() => {
    if (!values.city) {
      const defaultCity = "Beograd"; // Установите город по умолчанию
      handleChange({ target: { name: "city", value: defaultCity } });
    }
  }, [values.city]);

  return (
    <div className="register">
      <Link to="/">
        <img
          className="register__logo app__button-opacity"
          src={logo}
          alt="Логотип сайта"
        />
      </Link>
      <h2 className="register__text">
        {t("registr.welcome", "Добро пожаловать!")}
      </h2>
      {isPreloader && (
        <div className="preloader-abs">
          <Preloader isPreloader={isPreloader}></Preloader>
        </div>
      )}
      <form
        ref={formRef}
        className={
          isPreloader ? "register__form form--grey " : "register__form"
        }
        onSubmit={handleSubmit}
        noValidate
      >
        <label className="register__label" htmlFor="first_name">
          {t("registr.first_name", "Имя")}
          <input
            value={values.first_name || ""}
            onChange={handleChange}
            id="first_name"
            className="register__input"
            name="first_name"
            type="text"
            minLength="2"
            maxLength="150"
            pattern="[A-Za-zА-Яа-яЂ-џЈ-џђ-џљњћџČčĆćDždžĐđLjljNjnjŠšŽž]+$"
            required
          />
          <span
            className={`${
              errors.first_name || errorMessage.first_name
                ? "register__error"
                : "register__error_hidden"
            }`}
          >
            {errors.first_name ? (
              <>
                {t(
                  "errors.enter_text_of_min_two_letters",
                  "Введите имя или фамилию от 2 букв"
                )}
              </>
            ) : errorMessage?.first_name ? (
              <>{t(checkErrorCode("first_name", errorMessage.first_name))}</>
            ) : null}
          </span>
        </label>
        <label className="register__label" htmlFor="last_name">
          {t("registr.last_name", "Фамилия")}
          <input
            value={values.last_name || ""}
            onChange={handleChange}
            id="last_name"
            className="register__input"
            name="last_name"
            type="text"
            minLength="2"
            maxLength="150"
            pattern="^[A-Za-zА-Яа-яЂ-џЈ-џђ-џљњћџČčĆćDždžĐđLjljNjnjŠšŽž0-9]{2,150}+$"
            required
          />
          <span
            className={`${
              errors.last_name || errorMessage.last_name
                ? "register__error"
                : "register__error_hidden"
            }`}
          >
            {errors.last_name ? (
              <>
                {t(
                  "errors.enter_text_of_min_two_letters",
                  "Введите имя или фамилию от 2 букв"
                )}
              </>
            ) : errorMessage?.last_name ? (
              <>{t(checkErrorCode("last_name", errorMessage.last_name))}</>
            ) : null}
          </span>
        </label>
        <label className="register__label" htmlFor="city">
          {t("profile.city", "Город")}
          <select
            value={values.city}
            onChange={handleChange}
            id="city"
            className="register__input"
            name="city"
            required
          >
            {aboutUs?.map((city) => {
              return (
                <option key={city.city} value={city.city}>
                  {city.city}
                </option>
              );
            })}
          </select>
        </label>
        <label className="register__label" htmlFor="email">
          E-mail
          <input
            value={values.email || ""}
            onChange={handleChange}
            id="email"
            className="register__input"
            name="email"
            type="email"
            minLength="6"
            maxLength="254"
            pattern="^(http(s){0,1}:\/\/.){0,1}[\-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([\-a-zA-Z0-9@:%_\+.~#?&\/\/=]*)$"
            required
          />
          <span
            className={`${
              errors.email || errorMessage.email
                ? "register__error"
                : "register__error_hidden"
            }`}
          >
            {errors.email ? (
              <>
                {t(
                  "errors.enter_valid_email",
                  "Введите валидную почту(name@mail.com)"
                )}
              </>
            ) : errorMessage?.email ? (
              <>{t(checkErrorCode("email", errorMessage.email))}</>
            ) : null}
          </span>
        </label>
        <label className="register__label" htmlFor="phone">
          {t("registr.phone", "Телефон")}
          <input
            value={values.phone || ""}
            onChange={handleChange}
            id="phone"
            className="register__input"
            name="phone"
            type="phone"
            minLength="11"
            maxLength="128"
            pattern="^\+[0-9]{11,128}$"
            required
          />
          <span
            className={`${
              errors.phone || errorMessage.phone
                ? "register__error"
                : "register__error_hidden"
            }`}
          >
            {errors.phone ? (
              <>
                {t(
                  "errors.enter_valid_phone_starting_with_plus",
                  'Номер телефона должен начинаться с "+" и содержать от 11 до 14 цифр'
                )}
              </>
            ) : errorMessage?.phone ? (
              <>{t(checkErrorCode("phone", errorMessage.phone))}</>
            ) : null}
          </span>
        </label>
        <label className="register__label" htmlFor="password">
          {t("registr.password", "Пароль")}
          <input
            value={values.password || ""}
            onChange={handleChange}
            id="password"
            className="register__input"
            name="password"
            type="password"
            minLength="8"
            maxLength="128"
            pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,100}$"
            required
          />
          <span
            className={`${
              errors.password || errorMessage.password
                ? "register__error"
                : "register__error_hidden"
            }`}
          >
            {errors.password ? (
              <>
                {t(
                  "errors.enter_password_of_min_8_chars",
                  "Пароль должен состоять из цифр и латинских букв, длина не менее 8 символов"
                )}
              </>
            ) : errorMessage?.password ? (
              <>{t(checkErrorCode("password", errorMessage.password))}</>
            ) : null}
          </span>
        </label>
        {isSuccess && (
          <PopupRagistration
            message="registr.success"
            setIsPopup={setIsSuccess}
          ></PopupRagistration>
        )}
        <button
          className={`register__submit-button ${
            !isValid || isPreloader
              ? "register__submit-button_disable"
              : "app__button-opacity"
          }`}
          disabled={!isValid || isPreloader}
          type="submit"
          aria-label="Зарегистрироваться"
        >
          {t("registr.register_account", "Зарегистрироваться")}
        </button>
      </form>
      <div className="register__signup-container">
        <Link to="/login" className="register__signup app__text-opacity">
          {t("registr.already_registered", "Уже зарегистрированы?")}
          <p className="register__signup_red">{t("registr.login", "Войти")}</p>
        </Link>
        <Link to="/reset-password" className="login__signin app__text-opacity">
          <p className="login__signin_red">
            {t("registr.forgot_pass", "Забыли пароль?")}
          </p>
        </Link>
        <p className="register__error-text">
          {typeof errorMessage === "string" && t(errorMessage)}
        </p>
      </div>
    </div>
  );
}

export default Register;
