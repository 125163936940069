import { setDefaults, geocode, RequestType } from "react-geocode";

export async function handleGeocoder({ lat, lng }) {
  setDefaults({
    key: `${process.env.REACT_APP_GOOGLE_API_KEY}`, // Your API key here.
    language: "rs", // Default language for responses.
    region: "rs", // Default region for responses.
  });

  return await geocode(RequestType.LATLNG, `${lat},${lng}`, {
    language: "rs",
    region: "rs",
    enable_address_descriptor: true,
  })
    .then(({ results }) => {
      const address = results[0].formatted_address;

      return [address];
    })
    .catch(console.error);
}
